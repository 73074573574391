<template>
  <div>
    <v-layout wrap fill-height>
      <v-flex xs12>
        <v-snackbar v-model="showSnackBar" color="black">
          <v-layout wrap justify-center>
            <v-flex text-left class="align-self-center">
              <span style="color: white">
                {{ msg }}
              </span>
            </v-flex>
            <v-flex text-right>
              <v-btn small :ripple="false" text @click="showSnackBar = false">
                <v-icon style="color: white">mdi-close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-snackbar>
        <vue-element-loading
          :active="appLoading"
          :is-full-screen="true"
          background-color="#FFFFFF"
          color=" #13736f"
          spinner="spinner"
        />

        <v-layout wrap fill-height>
          <v-flex xs12 class="mainbg2" pt-3>
            <template class="mainbg2">
              <v-layout wrap pl-10 pr-10 justify-center>
                <v-flex xs5 style="border-radius: 8px" class="cus3">
                  <v-tabs
                    v-model="subtab"
                    :show-arrows="false"
                    color="#13736f"
                    background-color="transparent"
                    centered
                    dark
                  >
                    <v-tab to="#tabs-3"
                      ><span
                        class="mainfont"
                        style="
                          color: black;
                          font-size: 18px;
                          font-weight: bolder;
                        "
                      >
                        CURRENT</span
                      ></v-tab
                    >
                    <v-tab to="#tabs-4"
                      ><span
                        class="mainfont"
                        style="
                          color: black;
                          font-size: 18px;
                          font-weight: bolder;
                        "
                      >
                        UPCOMING</span
                      ></v-tab
                    >
                    <v-tab to="#tabs-5"
                      ><span
                        class="mainfont"
                        style="
                          color: black;
                          font-size: 18px;
                          font-weight: bolder;
                        "
                      >
                        PREVIOUS</span
                      ></v-tab
                    >
                  </v-tabs>
                </v-flex>
              </v-layout>
              <v-tabs-items v-model="subtab" id="custom-tab-items">
                <v-tab-item value="tabs-3">
                  <v-layout class="mainbg2" pa-4 wrap justify-start>
                    <v-flex xs12  pt-3 v-if="currentAuctions.length > 0">
                      <v-layout
                        wrap
                        v-for="(item, i) in currentAuctions"
                        :key="i"
                      >
                        <v-flex xs12 pl-5 pr-5>
                          <v-layout
                            justify-start
                            style="border-radius: 5px"
                            wrap
                            class="cardbg2"
                          >
                            <v-flex xs6 pa-3 text-left>
                              <span
                                class="mainfont"
                                style="
                                  color: #13736f;
                                  font-size: 15px;
                                  font-weight: bold;
                                "
                                >Auction No : {{ item.auctionNumber }}
                              </span>
                            </v-flex>

                            <v-flex xs6 pa-3 text-right>
                              <span
                                class="mainfont"
                                style="
                                  color: #13736f;
                                  font-size: 15px;
                                  font-weight: bold;
                                "
                                >Auction Date :
                                {{ formatDate(item.auctionDate) }}
                              </span>
                            </v-flex>
                          </v-layout></v-flex
                        >

                        <v-flex
                          lg2
                          pa-5
                          v-for="(item2, j) in item.data"
                          :key="j"
                        >
                          <v-card class="clrx">
                            <v-layout pr-4 pl-4 wrap justify-center>
                              <v-flex xs12>
                                <v-layout wrap>
                                  <v-flex xs12 pt-4 pl-2 pr-2 text-center>
                                    <v-avatar size="90px">
                                      <v-img
                                        :src="mediaURLnew + item2.categoryIcon"
                                      ></v-img>
                                    </v-avatar>
                                  </v-flex>
                                  <v-flex xs12 pt-2 text-center>
                                    <span
                                      class="mainfont"
                                      style="
                                        color: black;
                                        font-size: 14px;
                                        font-weight: bold;
                                      "
                                      >{{ item2.categoryName }}</span
                                    >
                                  </v-flex>
                                  <v-flex xs12 text-center>
                                    <span
                                      class="mainfont"
                                      style="
                                        color: black;
                                        font-size: 13px;
                                        font-weight: bold;
                                      "
                                    >
                                      {{ item2.weight }} Kg</span
                                    >
                                  </v-flex>
                                  <v-flex xs12 pb-2 text-center>
                                    <span
                                      class="mainfont"
                                      style="
                                        color: #797979;
                                        font-size: 13px;
                                        font-weight: bold;
                                      "
                                      >Lots {{ item2.lots }}</span
                                    >
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </v-flex>
                      </v-layout></v-flex
                    >
                    <v-flex xs12 pt-7 v-else text-center>
                      <span
                        class="mainfont"
                        style="color: black; font-size: 20px; font-weight: bold"
                        >No Current Auctions!</span
                      >
                    </v-flex>
                  </v-layout>
                </v-tab-item>
                <v-tab-item value="tabs-4">
                  <v-layout class="mainbg2" pa-4 wrap justify-start>
                    <v-flex xs12  pt-3 v-if="upcomingAuctions.length > 0">
                      <v-layout
                        wrap
                        v-for="(item, i) in upcomingAuctions"
                        :key="i"
                      >
                        <v-flex xs12 pl-5 pr-5>
                          <v-layout
                            justify-start
                            style="border-radius: 5px"
                            wrap
                            class="cardbg2"
                          >
                            <v-flex xs6 pa-3 text-left>
                              <span
                                class="mainfont"
                                style="
                                  color: #13736f;
                                  font-size: 15px;
                                  font-weight: bold;
                                "
                                >Auction No : {{ item.auctionNumber }}
                              </span>
                            </v-flex>

                            <v-flex xs6 pa-3 text-right>
                              <span
                                class="mainfont"
                                style="
                                  color: #13736f;
                                  font-size: 15px;
                                  font-weight: bold;
                                "
                                >Auction Date :
                                {{ formatDate(item.auctionDate) }}
                              </span>
                            </v-flex>
                          </v-layout></v-flex
                        >

                        <v-flex
                          lg2
                          pa-5
                          v-for="(item2, j) in item.data"
                          :key="j"
                        >
                          <v-card class="clrx">
                            <v-layout pr-4 pl-4 wrap justify-center>
                              <v-flex xs12>
                                <v-layout wrap>
                                  <v-flex xs12 pt-4 pl-2 pr-2 text-center>
                                    <v-avatar size="90px">
                                      <v-img
                                        :src="mediaURLnew + item2.categoryIcon"
                                      ></v-img>
                                    </v-avatar>
                                  </v-flex>
                                  <v-flex xs12 pt-2 text-center>
                                    <span
                                      class="mainfont"
                                      style="
                                        color: black;
                                        font-size: 14px;
                                        font-weight: bold;
                                      "
                                      >{{ item2.categoryName }}</span
                                    >
                                  </v-flex>
                                  <v-flex xs12 text-center>
                                    <span
                                      class="mainfont"
                                      style="
                                        color: black;
                                        font-size: 13px;
                                        font-weight: bold;
                                      "
                                    >
                                      {{ item2.weight }} Kg</span
                                    >
                                  </v-flex>
                                  <v-flex xs12 pb-2 text-center>
                                    <span
                                      class="mainfont"
                                      style="
                                        color: #797979;
                                        font-size: 13px;
                                        font-weight: bold;
                                      "
                                      >Lots {{ item2.lots }}</span
                                    >
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pt-7 v-else text-center>
                      <span
                        class="mainfont"
                        style="color: black; font-size: 20px; font-weight: bold"
                        >No Upcoming Auctions!</span
                      >
                    </v-flex>
                  </v-layout>
                </v-tab-item>
                <v-tab-item value="tabs-5">
                  <v-layout class="mainbg2" pa-4 wrap justify-start >
                    <v-flex pt-3 xs12 v-if="previousAuctions.length > 0">
                      <v-layout
                        wrap
                        v-for="(item, i) in previousAuctions"
                        :key="i"
                      >
                        <v-flex xs12 pl-5 pr-5>
                          <v-layout
                            justify-start
                            style="border-radius: 5px"
                            wrap
                            class="cardbg2"
                          >
                            <v-flex xs6 pa-3 text-left>
                              <span
                                class="mainfont"
                                style="
                                  color: #13736f;
                                  font-size: 15px;
                                  font-weight: bold;
                                "
                                >Auction No : {{ item.auctionNumber }}
                              </span>
                            </v-flex>

                            <v-flex xs6 pa-3 text-right>
                              <span
                                class="mainfont"
                                style="
                                  color: #13736f;
                                  font-size: 15px;
                                  font-weight: bold;
                                "
                                >Auction Date :
                                {{ formatDate(item.auctionDate) }}
                              </span>
                            </v-flex>
                          </v-layout></v-flex
                        >

                        <v-flex
                          lg2
                          pa-5
                          v-for="(item2, j) in item.data"
                          :key="j"
                        >
                          <v-card class="clrx">
                            <v-layout pr-4 pl-4 wrap justify-center>
                              <v-flex xs12>
                                <v-layout wrap>
                                  <v-flex xs12 pt-4 pl-2 pr-2 text-center>
                                    <v-avatar size="90px">
                                      <v-img
                                        :src="mediaURLnew + item2.categoryIcon"
                                      ></v-img>
                                    </v-avatar>
                                  </v-flex>
                                  <v-flex xs12 pt-2 text-center>
                                    <span
                                      class="mainfont"
                                      style="
                                        color: black;
                                        font-size: 14px;
                                        font-weight: bold;
                                      "
                                      >{{ item2.categoryName }}</span
                                    >
                                  </v-flex>
                                  <v-flex xs12 text-center>
                                    <span
                                      class="mainfont"
                                      style="
                                        color: black;
                                        font-size: 13px;
                                        font-weight: bold;
                                      "
                                    >
                                      {{ item2.weight }} Kg</span
                                    >
                                  </v-flex>
                                  <v-flex xs12 pb-2 text-center>
                                    <span
                                      class="mainfont"
                                      style="
                                        color: #797979;
                                        font-size: 13px;
                                        font-weight: bold;
                                      "
                                      >Lots {{ item2.lots }}</span
                                    >
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </v-flex>
                      </v-layout></v-flex
                    >
                    <v-flex pt-7 xs12 v-else text-center>
                      <span
                        class="mainfont"
                        style="color: black; font-size: 20px; font-weight: bold"
                        >No Previous Auctions!</span
                      >
                    </v-flex>
                  </v-layout>
                </v-tab-item>
              </v-tabs-items>
            </template>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      name: null,
      userProfileTabs: null,
      subtab: null,
      currentAuctions: [],
      upcomingAuctions: [],
      previousAuctions: [],
      appLoading: false,
      page: 1,
      pages: 0,
      dialog1: false,
      preview: null,
      tab: null,
      items: ["Dashboard", "Auctions"],
      dialog3: false,
      deletedialog: false,
      minAmount: null,
      password: null,
      categoryarray: [],
      formData: new FormData(),
      id: this.$route.query.id,
      productname: null,
      productarray: [],
      list: {},
      currentpage: 1,
      limit: 10,
      msg: null,
      showSnackBar: false,
      curid: [],
      dialog2: false,
    };
  },

  mounted() {
    this.getList();
  },
 
  methods: {
    formatTime(time) {
      return moment(time).format("HH:mm");
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/admin/dashboard/v1",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.currentAuctions = this.list.currentAuctions;
          this.upcomingAuctions = this.list.upcomingAuctions;
          this.previousAuctions = this.list.previousAuctions;
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.cus {
  background-color: #13736f;
}
.bg8 {
  border-radius: 10px;
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
.cardbg {
  background-image: linear-gradient(269.6deg, #29807c -31.66%, #29807c);
}

.cardbg2 {
  background-image: linear-gradient(269.6deg, white -31.66%, white);
}
.rounded-card {
  border-radius: 12px;
}
.mainbg2 {
  background-image: linear-gradient(269.6deg, #e7f1f0 -31.66%, #e7f1f0);
}
.clrx {
  box-shadow: 0px 2px 22px 0px rgba(221, 21, 21, 0.15);
}
.cus3 {
  height: 50px;
  background-color: white;
}
</style>